<template>
  <div>
    <div class="card mb-3" style="width: 100%" v-loading="loading" element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
      <div class="card-header bg-light">
        <h3 class="title fw-normal mb-0 p-2">Payment Integration List</h3>
        <el-row :gutter="12" class="integration-button-container">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-button type="info" round @click="integrations()">Integrations</el-button>
          </el-col>
        </el-row>
      </div>
      <el-row :gutter="12">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="table-container">
          <br /><br />
          <template v-if="getIntegratedList && getIntegratedList.cred_details">
            <el-table :data="pageconfiguration(getIntegratedList.cred_details)" border style="margin:auto; width: 95%">
              <el-table-column prop="accountName" label="Alias Name" />
              <el-table-column prop="pgType" label="PG Name" />
              <el-table-column prop="status" label="Status" />
              <el-table-column label="Actions">
                <template slot-scope="scope">
                  <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                      Actions<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <a @click="handleEdit(scope.row)"><el-dropdown-item>Edit</el-dropdown-item></a>
                      <!-- <a @click="handleShare(scope.row)"><el-dropdown-item>Share</el-dropdown-item></a> -->
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
            <center>
              <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-sizes="paginationProps"
              :page-size="pageSize"
              :pager-count="5"
              layout="prev, pager, next, jumper,sizes, total"
              :total="total"
              small>
              </el-pagination>
              <div style="margin-top: 10px;">Total {{ getIntegratedList.cred_details.length }} </div>
            </center>
          </template>
        </el-col>
      </el-row>
    </div>

    <!--added-->
    <el-dialog :title="`${ruleForm.pgType} Payment Gateway Details`" width="50%" top="5vh"
      :visible.sync="stripeApiDialog">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" label-width="120px"
        class="demo-ruleForm">

        <el-form-item label="Alias Name" prop="accountName">
          <el-input v-model="ruleForm.accountName"></el-input>
        </el-form-item>
        <el-form-item label="API Key" prop="apiKey">
          <el-input v-model="ruleForm.apiKey"></el-input>
        </el-form-item>
        <el-form-item label="Public Key" prop="publicKey">
          <el-input v-model="ruleForm.publicKey"></el-input>
        </el-form-item>
        <el-form-item label="status" prop="Status">
          <el-select  placeholder="Status"
          v-model="ruleForm.status">
          
            <el-option
              key="pay_ACTIVE"
              label="ACTIVE"
              value="ACTIVE"
            ></el-option>
            <el-option
              key="pay_INACTIVE"
              label="INACTIVE"
              value="INACTIVE"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="ruleForm.isDefault">Make it default payment system</el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="updateForm('ruleForm')">Update</el-button>
          <!-- <el-button @click="resetForm('ruleForm')">Reset</el-button> -->
        </el-form-item>
      </el-form>

    </el-dialog>
    <!-- upto this added-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PGInformationList",
  components: {},
  data() {
    //added
    return {
      loading: false,
      stripeApiDialog: false,
      paymentInfo: {},
      currentPage : 1,
      pageSize : 10,
      total : 0,
      ruleForm: {
        id:'',
        stripeKey: '',
        apiKey: "",
        publicKey: "",
        isDefault: false,
        accountName: "",
        status:'ACTIVE',

      },
      rules: {
        accountName: [{ required: true, message: 'Please enter Alias name', trigger: 'blur' },],
        stripeKey: [
          { required: true, message: 'Please enter stripe api key', trigger: 'blur' },
          // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
        ],
        apiKey: [
          { required: true, message: 'Please enter api key', trigger: 'blur' },
          // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
        ],
        publicKey: [
          { required: true, message: 'Please enter public key', trigger: 'blur' },
          // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
        ],
      }
    };
    //upto this added

  },
  computed: {
    ...mapGetters("paymentGateway", ["getPlansData", "getActivePaymentSystems", "getPaymentGatewayInfo", "getUpdateSystemInfo","getUpdateSystemInfoByTxnId", "getIntegratedList"]),
    ...mapGetters("auth", ["getActiveWorkspace"]),
  },
  created() { },
  mounted() {
    this.getActivePaymentSystemsData();

  },
  methods: {
    async integrations() {
      this.$router.push({ path: '/configuration/paymentSystems/add' });
    },
    // pageconfiguration(data,currentPage,pageSize){
    //   console.log(data,"this is dataaaaaaaaaaaaaaaaaaaaa")
    //   console.log(currentPage)
    //   console.log(pageSize)
    //   const filteredData =  Array.from({ length: pageSize }, (_, index) => `data ${index + (currentPage - 1) * pageSize + 1}`);
    //   console.log(filteredData,"this is filtered dataaaaaaaaaaaaa")
    // },
    handleSizeChange(val){
      this.pageSize = val;
    },
    handleCurrentChange(val){
      this.currentPage = val;
    },
    pageconfiguration(data) {
      this.total = data.length;
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      const pageItems = data.slice(startIndex, endIndex);
      return pageItems;
    },
    async getActivePaymentSystemsData() {
      this.loading = true;
      await this.$store.dispatch("paymentGateway/fetchInitSystemsData", this.getActiveWorkspace.company_id);
      this.loading = false;
    },


    handleEdit(row) {
      this.stripeApiDialog = true;
      console.log("stripeApiDialog:", row);
      this.paymentInfo = row;
      const decrypted = this.$CryptoJS.AES.decrypt(row.crypto, row.ivKey)
      const info = decrypted.toString(this.$CryptoJS.enc.Utf8);
      let data = JSON.parse(info);
      this.ruleForm= {
        id:row.reference_id,
        stripeKey: '',
        apiKey: data.apiKey,
        publicKey: data.publicKey,
        isDefault: row.isDefault,
        accountName:  row.accountName,
        status:row.status,
        pgType:row.pgType,

      }
      // this.ruleForm={...this.ruleForm,...info}
    },
    async paymentInit(info) {
      this.loading = true;
      await this.$store.dispatch("paymentGateway/fetchPaymentInit", { email: this.getAuthenticatedUser.email, client_id: this.getActiveWorkspace.company_id });
      
      if (this.getPaymentGatewayInfo && this.getPaymentGatewayInfo.ivKey) {
        this.stripeApiDialog = true
        this.paymentInfo = info

      }
      this.loading = false;
    },

    async updateForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const keys = this.$CryptoJS.AES.encrypt(JSON.stringify(this.ruleForm), this.getPaymentGatewayInfo.ivKey).toString();
          let requestInfo = {
            pgType: this.ruleForm.pgType,
            type: "APP",
            status: this.ruleForm.status, //"ACTIVE",
            crypto: keys,
            accountName: this.ruleForm.accountName,
            isDefault: this.ruleForm.isDefault
          }
          this.loading = true;
          await this.$store.dispatch("paymentGateway/updateInfoByTxnId", { accountId: this.getPaymentGatewayInfo._id,txnId:this.ruleForm.id, data: requestInfo, });

          if (this.getUpdateSystemInfoByTxnId) {
            this.$notify({
              title: "Success",
              message: `Payment System Info Updated`,
              type: "success",
            });
            this.$router.push({ path: "/configuration/paymentSystems" })
          }
          this.stripeApiDialog = false
          this.paymentInfo = {};
          this.ruleForm = {
            stripeKey: '',
            apiKey: "",
            publicKey: "",
            isDefault: false,
            accountName: "",
            status:'ACTIVE',
            id:'',

          };
          this.loading = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
        resetForm(formName) {
          this.$refs[formName].resetFields();
        }
  },
};
</script>
<style lang="scss" scoped>
.cropper {
  height: 600px;
  width: 600px;
  background: #ddd;
}

.paymentSty {
  cursor: pointer
}

.image-fit {
  //width:68%;
  height: 100%;
}

.clearfix {
  padding-bottom: 2rem;
}

.integration-button-container {
  position: absolute;
  top: 10px;
  right: 10px;
}

//upto this added
</style>
